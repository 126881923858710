import * as React from 'react';
import { gql } from '@urql/core';
import { Link } from 'gatsby';
import { CatalogLayout } from 'components/CatalogLayout';
import { BackButton } from 'components/BackButton';
import { useMyOrdersPageQuery } from 'generated/graphql';
import { MyOrderListItem } from 'components/MyOrderListItem';
import { AuthRequired } from 'components/AuthRequired';
import { Loader } from 'components/Loader';
import { PageError } from 'components/PageError';
import TimeBackIcon from 'components/icons/TimeBack';

const catalogLinkCn =
  'flex items-center h-10 font-medium text-orange-200 bg-orange-100 rounded-md uppercase tracking-wider px-14 py-6 mt-5 transition-opacity duration-300 ease-in-out hover:opacity-70';

const Empty = () => (
  <div className="flex flex-col text-center items-center justify-center h-full lg:h-auto lg:pt-16">
    <TimeBackIcon size={86} />
    <p className="text-2xl font-semibold mt-4 lg:text-lg">Нет заказов</p>
    <p className="text-lg mt-2 lg:text-base">Здесь будут отображаться ваши заказы</p>
    <Link to="/catalog" className={catalogLinkCn}>
      В Каталог
    </Link>
  </div>
);

const Content = () => {
  const [res, refetchQuery] = useMyOrdersPageQuery({
    requestPolicy: 'cache-and-network',
  });

  if (res.error) {
    const retry = () => refetchQuery({ requestPolicy: 'network-only' });
    return <PageError error={res.error} retry={retry} />;
  }

  if (res.fetching || !res.data) {
    return <Loader className="flex justify-center" />;
  }

  if (!res.data.viewer.user) {
    return <AuthRequired />;
  }

  const {
    features,
    user: { orders },
  } = res.data.viewer;
  if (orders.length === 0) {
    return (
      <div className="mb-12" style={{ minHeight: '400px' }}>
        <Empty />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-3 gap-5 2xl:grid-cols-2 sm:grid-cols-1" style={{ minHeight: '400px' }}>
      {orders.map((o, idx) => (
        <MyOrderListItem data={o} key={o.id} showStatusWidget={features.showLastOrder && idx === 0} />
      ))}
    </div>
  );
};

const MyOrders = () => (
  <CatalogLayout>
    <BackButton className="text-2xl font-bold mt-7 mb-11 lg:text-xl lg:mt-3 lg:mb-6">Мои заказы</BackButton>
    <Content />
  </CatalogLayout>
);

export default MyOrders;

gql`
  query MyOrdersPageQuery {
    viewer {
      id
      features {
        showLastOrder
      }
      user {
        id
        orders {
          id
          ...MyOrderListItem_data
        }
      }
    }
  }
`;
